

import React, {useState} from 'react'
import { Link } from "react-router-dom"
import {Container, Row, Col, Image, Card, Button} from 'react-bootstrap'

function timeoutPromise(interval) {
    return new Promise((resolve, reject) => {
    setTimeout(function(){
    resolve("done");
    }, interval);
});
};

export default function Code(props) {
        
        const [arrayToSelSort, setArrayToSelSort] = useState([4,2,5,1,7,9,6,3,8]);
        
        var arrayToSortComponent = arrayToSelSort.map((item) => 
            <Button variant="outlined" color="primary" style={{ margin: "3px" }}>{item}</Button>
        );



        const handleArrayToSortChange = (newSortedArray) => {
            setArrayToSelSort(newSortedArray)
            arrayToSortComponent = arrayToSelSort.map((item) =>  <Button variant="outlined" color="primary" style={{ textTransform: 'capitalize', margin: "3px" }}>{item}</Button> );
            setCount(count + 1);
        }
        const [count, setCount] = useState(0);

        const selectionSort = async () => {
            var arrayToSort = arrayToSelSort;
            for(let j = 0; j < arrayToSort.length; j++) {
                var leastPos = j
                for (let i = j + 1; i < arrayToSort.length; i++) {
                    if (arrayToSort[i] < arrayToSort[leastPos]) {
                        leastPos = i;
                    };
                }

                if (leastPos != j) {
                    [arrayToSort[j], arrayToSort[leastPos]] = [arrayToSort[leastPos], arrayToSort[j]];
                    // handleArrayToSortChange(arrayToSort)
                    // await timeoutPromise(3000);
                }
                
            }
            handleArrayToSortChange(arrayToSort)
        }

        // const insertionSort = () => {
        //     console.log(arrayToSort)
        // }
    return(
        <div>
            {arrayToSortComponent}
            <Button onClick={selectionSort}>Selection Sort</Button>
            {/* <div>
                <p>You clicked {count} times</p>
                <button onClick={() => setCount(count + 1)}> Click me </button>
            </div> */}
            {/* <h1>{arrayToSort}</h1>
            <Button>Insertion Sort</Button> */}
        </div>
        
    )
}