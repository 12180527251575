import React from 'react'
import { Link } from "react-router-dom"
import { Nav, Container, Navbar} from 'react-bootstrap'
import { Route, Routes} from "react-router-dom";
import logo from "../images/Vlogo.png"
export default function Header(props) {
    return(
        <Navbar bg="light" variant="light" sticky="top" className="background-color">
                {/* <Navbar.Brand href="#home"><img src="../images/oceanCliffs.jpg"></img></Navbar.Brand> */}
                <Navbar.Brand > <Link to={"/"}><img src={logo} className="logo"/> </Link> </Navbar.Brand>
                <Nav className="me-auto" >
                    <Link to={"/skills"}  className="m-2 p-2 text-center text-dark linkHeader"> SKILLS</Link>
                    <Link to={"/projects"}  className="m-2 p-2 text-center text-dark linkHeader"> PROJECTS </Link>
                    <Link to={"/about"}  className="m-2 p-2 text-center text-dark linkHeader"> ABOUT ME</Link>
                    {/* <Nav.Link href="#">Skills</Nav.Link>
                    <Nav.Link href="#features">Projects</Nav.Link>
                    <Nav.Link href="#pricing">About</Nav.Link> */}
                </Nav>
        </Navbar>
    )
}