import React from 'react'
import { Link } from "react-router-dom"
import {Container, Row, Col, Image, Card} from 'react-bootstrap'

export default function About(props) {
    return(
        <div className="main-body">

            <div className="greenblock">

            {/* <div className="myHobbies"> */}
            <Row className="text-center greenblock small-block">
                <Col sm="3"></Col>
                <Col sm="6">
                <h1 >More About Me</h1>
                <p> Here are a few things that show who I am off my computer. When I am not trying to learn a new technology, I am trying to expereince something new in the great outdoors.</p>
                </Col>
                <Col sm="3"></Col>
            </Row>
            <Row>
            {/* <Col sm="4">
                <Image src="/static/media/D65A2E86-4E0E-4007-84C3-C8958963B404 (1).24be6307.JPG" className="mountains" />
                <p>I love camping and being in the outdoors. </p>
            </Col> */}
            <Col sm="4">
                <Card className="about-card">
                <Card.Body>
                    {/* <div className="mountains cardPhoto"></div> */}
                    
                    <Card.Title className="mountains cardPhoto">
                    </Card.Title>
                    <Card.Text>
                        I love camping in the mountains and exploring new areas. I also love taking pictures of beautful areas like this.
                    </Card.Text>
                </Card.Body>
                </Card>
            </Col>
            <Col sm="4">
                <Card className="about-card">
                <Card.Body>
                    <Card.Title className="scuba cardPhoto"></Card.Title>
                    <Card.Text>
                        Scuba diving is like being in a whole different world. I love to admire the little fish in the sea, and all the amazing things that you can find in the ocean.
                    </Card.Text>
                </Card.Body>
                </Card>
            </Col>
            <Col sm="4">
                <Card className="about-card">
                <Card.Body>
                    <Card.Title className="boat cardPhoto"></Card.Title>
                    <Card.Text>
                        River rafting is something that has helped me grow to be the person I am today. I was a river guide on the salmon river that goes through the middle of idaho.
                    </Card.Text>
                </Card.Body>
                </Card>
            </Col>
            </Row>
            <Row>
            <Col sm="4">
                <Card className="about-card">
                <Card.Body>
                    <Card.Title className="dog cardPhoto"></Card.Title>
                    <Card.Text>
                        This is my dog Milo, he loves chasing tennis balls. I love watching him run and explore.
                    </Card.Text>
                </Card.Body>
                </Card>
            </Col>
            <Col sm="4">
                <Card className="about-card">
                <Card.Body>
                    <div className="arch cardPhoto"></div>
                    <Card.Text>
                        The night sky is incredibly beautiful, and I have worked on gaining the skill of capturing just a piece of it's beauty
                    </Card.Text>
                </Card.Body>
                </Card>
            </Col>
            <Col sm="4">
                <Card className="about-card">
                <Card.Body>
                    <div className="hiking cardPhoto"></div>
                    <Card.Text>
                    "There are no shortcuts to any place worth going."
                    </Card.Text>
                </Card.Body> 
                </Card>
            </Col>
            </Row>
        </div>
            {/* </div> */}
    </div>
    )
}