import logo from './logo.svg';
import React, { useState } from 'react';
import './App.css';
import { Route, BrowserRouter, Routes} from "react-router-dom";
import {Container, Row, Col, Image, Card} from 'react-bootstrap'
import Header from './Components/Header';
import Home from './Components/Home'
import About from './Components/About'
import Project from './Components/Projects'
import Skills from './Components/Skills'
export default function App() {

  return (
    <BrowserRouter>
      <Container fluid noGutters>
        <Row className="header">
          <Header />
        </Row>
        <Routes>
          <Route exact path="/about" element={<About/>}> Home</Route>
          <Route exact path="/projects" element={<Project/>}> Home</Route>
          <Route exact path="/skills" element={<Skills/>}/>
          <Route exact path="/" element={<Home/>}/>
        </Routes>
      </Container>
    </BrowserRouter>
  );
}