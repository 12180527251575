import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom"
import {Container, Row, Col, ProgressBar, ListGroup, Carousel, Image, Button, Form} from 'react-bootstrap'
import Code from './Code'
import axios from 'axios'

export default function Skills(props) {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    // const [newTrivia, setNewTrivia] = useState(false);
    // const handleNewTrivia = () => {
    //     setNewTrivia(true)
    // }
    // const [trivia, setTrivia] = useState(String);
    // const [triviaAnswer, setTriviaAnswer] = useState(String);


    // useEffect(() => {
    //     // work on doing this multiple 
    //     const settingTrivia = async () => {
    //         let funfacts = await getTrivia();
    //         // let funfacts = tagsInfo.relations[0].secondID.split(",")

    //         setTrivia(funfacts);
    //         console.log("done setting trivia")
    //         setNewTrivia(false);
    //     }
    //     if (newTrivia) {
    //         settingTrivia()
    //         console.log("setting new trivia")
    //         setNewTrivia(false);
    //     }
    //     else {
    //         console.log(trivia)
    //         return

    //     }
    // }, [trivia, setTrivia, newTrivia])
    // const getTrivia = async () => {
    //     //KddjMMnwiuFoDNYujBZJdweF
    //     //curl -X GET "https://api.fungenerators.com/trivia?id=100" -H "accept: application/json" -H "X-Fungenerators-Api-Secret: KddjMMnwiuFoDNYujBZJdweF"
    //     const randomNumber = Math.floor(Math.random() * 100).toString();
    //     return axios.get('https://api.kanye.rest'
    //     )

    //         .then(response => {
    //             console.log("tried to get a trivia item")
    //             console.log(response.data)
    //             return response.data
    //         })
    //         .catch(error => {
    //             console.log("HTTP Request Error")
    //             return;
    //         })
    // }


    return(
        <div className="main-body">
        {/* <Row className="text-center tanblock">
            <Col xs="1">
            </Col>
            <Col sm="4" className="text-left">
                <h2>Riddles API</h2>
                <Button onClick={handleNewTrivia}>See a Riddle Item</Button>  
            </Col>
            <Col xs="4">
            <p>A large portion of these hard skills were developed while getting my masters in Information Systems and solidified in the professional feild.</p> 
            </Col>
            <div class="embed-fungen"> 
            <script src="https://fungenerators.com/extensions/embed/v1/fungen.js"></script> 
            <script> FunGen.Embed.render( { context: 'random', toolId: 'riddle' } ); </script> 
            <noscript>Please enable JavaScript to view the <a href="https://fungenerators.com/random/riddle">Riddle Generator from fungenerators.com.</a></noscript>
            <div class="light-text" style="text-decoration: none; color:#ccc; text-align: right; padding-right: 20px; margin-top: -20px;">
            <a href="https://fungenerators.com/random/riddle" title="Powered by Riddle Generator from fungenerators.com" style="text-decoration: none; color:#ccc; ">fungenerators.com</a>
            </div> 
            </div>
        </Row> */}


        <Row className="text-center tanblock">
            <Col xs="1">
            </Col>
            <Col sm="10">
                <h2>More about my Experience</h2>
                <p>A large portion of these hard skills were developed while getting my masters in Information Systems and solidified in the professional feild.</p>   
                <Code/>
            </Col>
            <Col xs="1">
            </Col>
        </Row>
        <Row className="skills">
            {/* <Carousel activeIndex={index} onSelect={handleSelect}>
                <Carousel.Item> */}
                <Col sm={6} className="p-4">
                    <h3>SKILLS </h3>
                    <ListGroup>
                        <ListGroup.Item>
                            React.JS
                            <ProgressBar variant="primary" now={90} />
                        </ListGroup.Item>
                        <ListGroup.Item>
                            AWS Cloud Architecture
                            <ProgressBar variant="primary"  now={60} />
                        </ListGroup.Item>
                        <ListGroup.Item>
                            Data Visualization
                            <ProgressBar variant="primary" now={75} />
                        </ListGroup.Item>
                        <ListGroup.Item>
                            Tableau
                            <ProgressBar variant="info" now={45} />
                        </ListGroup.Item>
                        <ListGroup.Item>
                            Database CRUD
                            <ProgressBar variant="secondary" now={35} />
                        </ListGroup.Item>
                    </ListGroup>
                </Col>
                <Col sm={6} className="skills-photo p-4"></Col>
            </Row>
            <Row className="skills">
                <Col sm={6} className="languages-photo p-4"></Col>
                <Col sm={6} className="p-4">
                    <h3>Languages</h3>
                        <ListGroup>
                        <ListGroup.Item>
                            Javascript
                        </ListGroup.Item>
                        <ListGroup.Item>
                            Typescript
                        </ListGroup.Item>
                        <ListGroup.Item>
                            HTML/CSS
                        </ListGroup.Item>
                        <ListGroup.Item>
                            C++
                        </ListGroup.Item>
                        <ListGroup.Item>
                            Java
                        </ListGroup.Item>
                        <ListGroup.Item>
                            Python
                        </ListGroup.Item>
                        <ListGroup.Item>
                            C#
                        </ListGroup.Item>
                        <ListGroup.Item>
                            SQL
                        </ListGroup.Item>
                        <ListGroup.Item>
                            JSON
                        </ListGroup.Item>
                        </ListGroup>
                    </Col>
                    {/* </Carousel.Item>
                </Carousel> */}
            </Row>
            <Row className="skills">
                <Col sm={6} className="p-4">
                {/* </Carousel.Item>
                    <Carousel.Item> */}
                    <h3>College Courses</h3>
                    <ListGroup>
                        <ListGroup.Item>
                            Data Structures
                        </ListGroup.Item>
                        <ListGroup.Item>
                            Discrete Stuctures
                        </ListGroup.Item>
                        <ListGroup.Item>
                            Advanced Programming Concepts
                        </ListGroup.Item>
                        <ListGroup.Item>
                            Web Programming
                        </ListGroup.Item>
                        <ListGroup.Item>
                            Systems Analysis and Design
                        </ListGroup.Item>
                        <ListGroup.Item>
                            Database Systems
                        </ListGroup.Item>
                        <ListGroup.Item>
                            Buisness Programming
                        </ListGroup.Item>
                        <ListGroup.Item>
                            Algorithm Design and Analysis
                        </ListGroup.Item>
                    </ListGroup>
                </Col>
                <Col sm={6} className="college-photo p-4"></Col>
                    {/* </Carousel.Item>
                    <Carousel.Item> */}
            </Row>
            <Row className="tanblock">
                <Col sm="6">
                    <h4>Do You have a skill to recommend?</h4>
                    <p>I am always trying to improve my skills and I would love suggestions on what I should learn next.</p>   
                </Col>
                {/* <Col sm="6">
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Suggestion</Form.Label>
                            <Form.Control placeholder="What skill I should learn" />
                        </Form.Group>
                        <Button variant="dark" type="submit">
                            Submit
                        </Button>
                    </Form>
                </Col> */}
            </Row>
            </div>
        )
    }