import React, { useState } from 'react';
import { Link } from "react-router-dom"
import {Container, Row, Col, ProgressBar, ListGroup, Carousel, Image, Button, Navbar} from 'react-bootstrap'

export default function Project(props) {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };
    return(
        <div className="main-body">
            <div className="greenblock">
        <Row className="text-center greenblock">
            <h1 >What I am currently working on</h1>
            <p>I am currently working in the small start up space. </p>
        </Row>
        <Row className="now p-5">
            <Col>
                <Row>
                    <Col sm="6" className="zipZapPic"></Col>
                    <Col sm="6" className="zipZapInfo px-4">        
                    <h4 className="company-title-zip my-2"><a href="http://app.zipzapgifts.com" target="_blank">ZIP ZAP GIFTS</a></h4>
                    <p>I am building a web application for Zip Zap Gifts that allows users to keep track of important gifts and automate the gift giving process. This software will help remind people of 
                        important events and remove the worry of forgetting someones birthday. I enjoy working on this software becuase it lets me improve my skills while working on a fun project. 
                        I really enjoy the team I am working with, and I am excited to launch the product in February. See the Beta now at <a href="http://app.zipzapgifts.com" target="_blank"> app.zipzapgifts.com</a> Or see the company landing page at <a href="zipzapgifts.com" target="_blank">Zip Zap Gifts</a>
                    </p></Col>
                </Row>
                <hr className="spacer"/>
                <Row>
                    {/* <hr className="spacer m-4"/> */}
                    <Col sm="6" className="px-3 zidaCol">
                    <h4 className="company-title py-2"><a href="https://www.zidatravel.com/" target="_blank">ZIDA TRAVEL</a></h4>
                    <p> I am on a more personal project with a team of people who are working on creating a new company calledbZida travel. 
                        I have been with this team since the creating of the idea. With this company we have done extensive user research to find a pain point in the travel industry
                        We are currently working on a social media for traveling that allows people to share their trip details with friends and family.
                        I am currently working on a web application in React for this product and in the future I will work on a React Native app to acompany the application
                        See the Beta now at <a href="https://www.zidatravel.com/" target="_blank">zidatravel.com</a>
                    </p>

                    </Col>
                    <Col sm="6" className="zidaInfo"></Col>
                </Row>
                <hr className="spacer"/>
                <Row >
                    <Col sm="6" className="xolitudePic px-4"></Col>
                    <Col sm="6" className="xolitudeInfo px-4">        
                    <h4 className="company-title-zip my-2">XOLITUDE</h4>
                    <p>I am building am E-Commerse store for a photographer so they can start selling thier photos. I have creates similar sites to this and I am excited to be able to use my skills to help a small buisness. The site will be launched by April 2022 at xolitude.com
                        {/* <link url="app.zipzapgifts.com"> */}
                            app.zipzapgifts.com
                            {/* </link> */}
                    </p></Col>
                </Row>
            </Col>


        </Row>
        </div>
        </div>
        )
    }