import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom"
import {Container, Row, Col, ProgressBar, ListGroup, Carousel, Image, Button, Card} from 'react-bootstrap'
import axios from 'axios'

export default function Home(props) {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };
    const [newTrivia, setNewTrivia] = useState(true);
    const handleNewTrivia = () => {
        setNewTrivia(true)
    }
    const [trivia, setTrivia] = useState(String);
    const [triviaAnswer, setTriviaAnswer] = useState(String);


    useEffect(() => {
        // work on doing this multiple 
        const settingTrivia = async () => {
            let funfacts = await getTrivia();
            // let funfacts = tagsInfo.relations[0].secondID.split(",")

            // setTrivia(funfacts);
            console.log("done setting trivia")
            setNewTrivia(false);
        }
        if (newTrivia) {
            settingTrivia()
            console.log("setting new trivia")
            setNewTrivia(false);
        }
        else {
            console.log(trivia)
            return

        }
    }, [trivia, setTrivia, newTrivia])
    const getTrivia = async () => {
        //KddjMMnwiuFoDNYujBZJdweF
        //curl -X GET "https://api.fungenerators.com/trivia?id=100" -H "accept: application/json" -H "X-Fungenerators-Api-Secret: KddjMMnwiuFoDNYujBZJdweF"
        const randomNumber = Math.floor(Math.random() * 100).toString();
        return axios.get('https://api.kanye.rest'
        )

            .then(response => {
                console.log("tried to get a trivia item")
                console.log(response.data)
                setTrivia(response.data.quote)

                return response.data
            })
            .catch(error => {
                console.log("HTTP Request Error")
                return;
            })
    }

    return(
        <div className="main-body">
            <Row className="App-header m-4">
                    <Col sm={1} className="header-padding p-3"> </Col>
                    <Col xs={12} sm={5} className="intro-header pt-4">
                        <h1 className="name-header mt-4">HELLO, I AM VICTORIA.</h1>
                            <div className="why">
                                {/* <h3>Why me?</h3> */}
                                
                                <p>Welcome to a little site that I created to help you know me better.</p>
                                <p>I create applications with the user in mind. I belive in creating software that is both visual appealing and technically efficient</p>
                            </div>
                            <div className="email"><Button variant="secondary" className="px-5 btn-green" href="mailto:victoria.skylan@gmail.com"> Email Me</Button></div>
                    </Col>
                    <Col xs={12} sm={1}  className="header-padding p-4"></Col>
                    <Col xs={12} sm={5} className="header-padding p-3">
                        <div className="myBackground"> </div>
                        <div className="photoOfMeContainer">
                            <Image src="/static/media/BlackVictoria.4ebdf4e0.jpg" className="photoOfMe"/>
                        </div>
                    </Col>
            </Row>
                <div className="aboutMe">
                    <Row className="quote-api">
                        <Col sm={1} ></Col>
                        
                        <Col sm={10}>
                            <h1>Random Quote from the Kanye API</h1>
                            <p className="quote">"{trivia}" - Kanye West</p>
                            <hr/>
                            <p className="quoteEx">Is this because I like Kayne? no it is because it is a funny API that is also free, so I added it to show off some API skills</p>
                            <Button onClick={handleNewTrivia}>Get new quote</Button>
                            
                        </Col>
                        <Col sm={1} ></Col>

                    </Row>
                    <Row className="blackBack">
                        
                        <Col sm={8}>
                            <h1 className="about-me-header p-4">About Me</h1>
                            <Card>
                                <p className="about-me-info">I am a creative and analytical thinker, and I am passionate about developing my talents. I enjoy designing software to promote efficiencies and a great user experience.
                                <br/><br/>
                                Software development gives me the excitement and fullfillment. I enjoy learning new languages and I am excited to see what I can do with the knowledge that my education has given me.</p>
                            </Card>
                        </Col>
                        <Col sm={4}>
                            <h1 className="about-me-header p-4">Languages</h1>
                            <ListGroup>
                            <ListGroup.Item>
                                Javascript
                            </ListGroup.Item>
                            <ListGroup.Item>
                                Typescript
                            </ListGroup.Item>
                            <ListGroup.Item>
                                HTML/CSS
                            </ListGroup.Item>
                            <ListGroup.Item>
                                C++
                            </ListGroup.Item>
                            <ListGroup.Item>
                                Python
                            </ListGroup.Item>
                            </ListGroup>
                        </Col>
                        {/* <Col>
                            <div className="myBackground"> </div>
                            <div className="photoOfMeContainer">
                            <Image src="/static/media/BlackVictoria.4ebdf4e0.jpg" className="photoOfMe"/>
                            </div>
                        </Col> */}
                    </Row>
                </div>
            </div>
        )
    }